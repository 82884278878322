import React, { useEffect } from 'react';

import { Sidebar, Banner, BestSeller, DealDaily, FeatureProducts, CustomSlider } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import icons from '../../utils/icons';
import { getFeatureProducts } from '../../store/products/asyncActions';
const { IoIosArrowForward } = icons;
const Home = () => {
  const dispatch = useDispatch();
  const { newProducts, featureProducts } = useSelector((state) => state.products);

  const { categories } = useSelector((state) => state.app);
  useEffect(() => {
    dispatch(getFeatureProducts());
  }, [dispatch]);
  return (
    <>
      <div className="w-main flex mt-6">
        {/* <div className="flex flex-col gap-5 w-[25%] flex-auto">
          <Sidebar />
          <DealDaily />
        </div> */}
        <div className="flex flex-col gap-5 w-[100%] flex-auto">
          <Banner />
          <BestSeller />
        </div>
      </div>
      {/* <div className="w-main my-8">
        <h3 className="text-[20px] font-semibold py-[15px] border-b-2 border-main">SẢN PHẨM NỔI BẬT</h3>
        <div className="mt-4 mx-[-10px] ">
          <CustomSlider products={featureProducts} slidesToShow={4} />
        </div>
      </div> */}
      <div className="w-main my-8">
        <h3 className="text-[20px] font-semibold py-[15px] border-b-2 border-main">SẢN PHẨM MỚI</h3>
        <div className="mt-4 mx-[-10px] ">
          <CustomSlider products={newProducts} slidesToShow={4} />
        </div>
      </div>
      {/* <div className="w-main my-8">
        <h3 className="text-[20px] font-semibold py-[15px] border-b-2 border-main">
          HOT COLLECTIONS
        </h3>
        <div className="flex flex-wrap gap-4 mt-4">
          {categories
            ?.filter((el) => el.brand.length > 0)
            ?.map((el) => (
              <div key={el._id} className="w-[396px]">
                <div className="border flex p-4 gap-4 min-h-[190px]">
                  <img
                    src={el?.image}
                    alt="img category"
                    className="w-[144px] flex-1 h-[129px] object-cover"
                  />
                  <div className="flex-1 text-gray-700">
                    <h4 className="font-semibold uppercase">{el.title}</h4>
                    <ul className="text-sm">
                      {el?.brand?.map((item) => (
                        <span
                          key={item}
                          className="flex gap-1 items-center text-gray-500"
                        >
                          <IoIosArrowForward size={14} />
                          <li>{item}</li>
                        </span>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div> */}
      {/* <div className="my-8 w-main">
        <h3 className="text-[20px] font-semibold py-[15px] border-b-2 border-main">
          BLOG POSTS
        </h3>
      </div> */}
    </>
  );
};

export default Home;
